<style lang="scss">
.digitales-portfolio {
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    h2 {
    }
    .bild-box {
      width: 100%;

      background: #333;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }

    .collapse-content-link {
      display: inline-block;
      font-weight: 500;
      color: $blue-darker;
    }
  }
  .collapse-content-link-v2 {
    background: none;
    padding: 0;
    display: inline-block;
    font-weight: 500;
    color: $blue-darker;
    border: 0;
    outline: none;
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }

  .rating {
    display: none;
    margin: 0;
    border-top: 2px solid $blue-darker;
    padding-top: 20px;
    padding-bottom: 20px;
    .header {
      margin-bottom: 10px;
      @media (max-width: 768px) {
        text-align: center;
      }
      strong {
        background: $blue-darker;
        color: #fff;
        width: 50px;
        height: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 130%;
        border-radius: 50%;
      }
      span {
        display: inline-block;
        margin-left: 10px;
        @media (max-width: 992px) {
          margin-left: 0;
          display: block;
          margin-top: 5px;
        }
      }
    }
    .col-md-4 {
      text-align: center;
      border-right: 1px solid #ccc;
      @media (max-width: 992px) {
        border-right: 0;
      }
      .rating-content {
        .name {
          margin-top: 10px;
          margin-bottom: 5px;
        }
        .stars {
        }
      }
    }
    .border-right-none {
      border-right: 0;
    }
  }
}
</style>

<template>
  <div class="content digitales-portfolio container">
    <h1>Programmatic Advertising</h1>
    <p class="intro-txt">Der neue Weg für effiziente Online Werbung auf kleinezeitung.at und darüber hinaus! Mit dem Programmatic Advertising Tool der Kleinen Zeitung nehmen Sie Ihre Werbekampagne selbst in die Hand. Sie buchen direkt, unkompliziert und schnell genau im Interessensgebiet Ihrer Zielgruppe und steuern die Ausspielung Ihrer Werbemittel nach geografischen Parametern.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-programmatic.jpg" alt="Programmatic" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Programmatic Tool</h2>
          <p>
            Programmatic Advertising: <CollapsibleButton link="zukunftdisplay" class="collapse-content-link">die Zukunft der Displaywerbung</CollapsibleButton>: Was futuristisch klingt, ist nichts anderes als die Weiterentwicklung klassischer Displaywerbung. Mit dem Tool der Kleine Zeitung können auch Sie ganz einfach und übersichtlich alle Vorteile dieser modernen Werbeform nutzen und das
            vollkommen ohne Lizenz- und Nutzungsgebühren.
          </p>
          <div class="preis-box">
            <span>Tarif ab:<strong>500,00</strong> pro Kampagne</span>
          </div>
          <CollapsibleButton link="programmatic-tool" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Programmatic Tool</CollapsibleButton>
        </div>
      </div>
    </div>

    <!--<button @click='toggle = !toggle'> click here asdf asdf as </button>
    <div v-show='toggle'> <h3>Programmatic Advertising im Vormarsch</h3>
      <img src="@/assets/img/img-content/digital/anstieg-programmatic-advertising.jpg" alt="Anstieg Programmatic" class="img-fluid shadow" />
    </div>-->

    <Collapsible link="zukunftdisplay">
      <h3>Programmatic Advertising im Vormarsch</h3>
      <img src="@/assets/img/img-content/digital/anstieg-programmatic-advertising.jpg" alt="Anstieg Programmatic" class="img-fluid shadow" />
    </Collapsible>

    <Collapsible link="programmatic-tool">
      <h2>Was genau bringt mir Displaywerbung über das Programmatic Tool?</h2>
      <ul class="content-ul">
        <li>Maximierung der Sichtkontakte zum eingesetzten Budget durch automatisierte Kampagnenoptimierung ausgeklügelter Algorithmen</li>
        <li><button @click="toggle2 = !toggle2" class="collapse-content-link-v2">genaues Targeting der Zielgruppe</button> (geografisch sowie nach Interessen)</li>
        <li>Minimierung der Streuverluste</li>
        <li>volle Kontrolle über die Kampagne dank <button @click="toggle = !toggle" class="collapse-content-link-v2">tagesaktuellem Reporting</button></li>
        <li>kein Marketing- oder IT-Studium nötig, um das Tool zu bedienen. Lediglich Kenntnis der eigenen Zielgruppe erforderlich</li>
      </ul>

      <div v-show="toggle">
        <img src="@/assets/img/img-content/digital/progra-v2-reporting.jpg" alt="Reporting" class="img-fluid" />
      </div>
      <div v-show="toggle2">
        <img src="@/assets/img/img-content/digital/geo_targeting.jpg" alt="Geo Targeting" class="img-fluid" />
      </div>

      <div class=" tipp-box">
        Programmatische Displaywerbung ist der beste Weg um digital <strong>Bedarf zu wecken</strong>, sich in <strong>den Köpfen potenzieller Kunden einzunisten</strong> und <strong>Branding</strong> sowie <strong>Imagewerbung</strong> zu betreiben. Der Algorithmus wählt dafür aus einer Vielzahl an Werbeplätzen die individuell besten aus und erreicht mit der
        <strong>Maximierung der Anzahl an Sichtkontakten</strong> das jeweilige Werbeziel.
      </div>
      <div class="info-box"><strong>Hinweis:</strong> Wollen Sie bereits vorhandenen Bedarf decken und möglichst hohe Klickzahlen erreichen, eignen sich Performance-Marketing Strategien besser als Displaywerbung.</div>

      <h2>Was sind die großen Stärken des Tools der Kleinen Zeitung?</h2>
      <ul class="content-ul">
        <li>kostenlos: keine Lizenz-, Installations- oder Nutzungsgebühren</li>
        <li>einfache Bedienung</li>
        <li>Service und Beratung inklusive</li>
        <li>Brand Safety und Ausspielung in hochwertigem Markenumfeld</li>
      </ul>
      <div class="hinweis-box">
        Warum das Markenumfeld erfolgskritisch für Ihre Kampagne ist, zeigt die unabhängige <strong><a href="../../pdf/digital/The_Ripple_Effect-de.pdf" target="_blank">Ripple-Effekt-Studie</a>.</strong>
      </div>

      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>1</strong>
          <span>brand safety</span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
          <div class="rating-content">
            <div class="name">Premium</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
          <div class="rating-content">
            <div class="name">Open</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>2</strong>
          <span>Möglichkeiten des Zielgruppen-Targetings </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>3</strong>
          <span>Reichweite erzielen, Aufmerksamkeit im hochwertigen Markenumfeld </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>4</strong>
          <span>Bedarf wecken (erreichen Sie Kunden, die noch nicht aktiv nach einem Produkt suchen) </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>5</strong>
          <span>Bedarf decken? </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>6</strong>
          <span>Einnisten in Köpfe der Kunden, im Bedarfsfall als 1. Gedanke erinnert werden </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <div class="row rating">
        <div class="col-lg-12 header">
          <strong>7</strong>
          <span>Markenaufbau / Branding / Imagewerbung </span>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Kleine Zeitung</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="rating-content">
            <div class="name">Google</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
        <div class="col-md-4 border-right-none">
          <div class="rating-content">
            <div class="name">Facebook</div>
            <div class="stars">
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
              <i class="material-icons">grade</i>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
      <br /><br />
      <h2>Meine perfekte Kampagne im Programmatic Tool</h2>
      <p>Passen Sie die Ausspielung nach Belieben Ihrem individuellen Werbebedarf an:</p>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/reichweite-vs-webseitenbesuche.jpg" alt="Reichweite" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li><strong>Reichweite:</strong> Die Algorithmen optimieren auf die <strong>maximale Anzahl an Sichtkontakten</strong> zum eingesetzten Budget.</li>
            <li><strong>Webeseitenbesuche:</strong> Die Algorithmen bevorzugen Formate und Werbeplätze mit einer höheren Klickwahrscheinlichkeit .</li>
          </ul>
        </div>
      </div>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
          <img src="@/assets/img/img-content/digital/premium-vs-standard.jpg" alt="Premium vs Standard" class="img-fluid shadow" />
          <img src="@/assets/img/img-content/digital/premium-portfolio.jpg" alt="Premuim  Portfolio" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li>Im zweiten Schritt können Sie sich für eines der <strong>umfangreichen Programmatic Premium Produkte</strong> entscheiden oder über „Programmatic-Standard“ zur Ausspielungsvariante <strong>„Open“ </strong> navigieren.</li>
          </ul>
        </div>
      </div>

      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/progra-v4-laufzeit.jpg" alt="Produkte" class="img-fluid shadow" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <ul class="content-ul">
            <li>Budget, Laufzeit und Landing Page schneiden Sie einfach punktgenau für Ihre Kampagne zu.</li>
            <li><strong>Tipp:</strong> Für optimale Kampagnenergebnisse empfehlen wir eine <strong>Mindestlaufzeit von 14 Tagen.</strong></li>
          </ul>
        </div>
      </div>
      <div class="tipp-box text-center">Hier geht es zum <a href="https://kleinezeitung.cc.adunit.at/" target="_blank">Programmatic-Tool</a></div>
      <br /><br />
      <h2>Wie viel Budget soll ich für eine Programmatic Kampagne einplanen?</h2>
      <p>Basierend auf den von Ihnen im Tool gewählten Parametern zur Ausspielung der Kampagne, kann unser Tool bereits direkt bei der Eingabe des Werbebudgets eine voraussichtliche Anzahl an Sichtkontakten errechnen. So können Sie das gewünschte Werbeziel mit dem vorhandenen Budget abstimmen und die für Sie individuell besten Parameter fixieren.</p>

      <h3>Benötigen Sie Hilfe beim Abschätzen der verfügbaren Zielgruppe?</h3>

      <p>
        Unser Zielgruppen-Potential-Rechner (ZPR) verrät Ihnen, wie viele Menschen mit Internetanschluss Sie mit den von Ihnen gewählten Interessensgebieten in den jeweiligen Regionen erreichen können. Das vorgeschlagene Budget gibt an, welche Summe Sie benötigen, um jede dieser Personen im Zuge Ihrer Kampagne viermal zu bespielen, was einem 100 prozentigen Werbedruck entspräche. Wichtig: Der
        Rechner liefert also keinesfalls eine eindeutige Empfehlung für Ihre spezielle Kampagne, soll Ihnen und Ihrem Kleine-Zeitung-Berater aber eine Hilfestellung geben, um ein Gefühl für die vorhandene Zielgruppengröße zu bekommen.
      </p>
      <div class="tipp-box text-center">Hier geht es zum <a href="https://klz-verkauf.at/wp-content/uploads/vu-daten/Zielgruppenrechner/index.html" target="_blank">Zielgruppen-Potential-Rechner</a></div>
      <!-- -->
    </Collapsible>
    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-auto-immo-job.jpg" alt="Auto / Immo / Job" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Mobilität? Wohnen? Karriere?</h2>
          <p>Für die Marktplätze Mobilität, Wohnen und Karriere profitieren Sie von individuell angepassten Premium-Produkten, die mit einer maßgeschneiderten Ausspielung aufwarten können.</p>
          <div class="preis-box">
            <span>Tarif ab:<strong>500,00</strong></span>
          </div>
          <CollapsibleButton link="karriere-arbeit-wohnen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zu den Themen-Targetings</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="karriere-arbeit-wohnen">
      <div class="row">
        <div class="col-lg-12 justify-content-center align-self-center">
          <h3>Was bringen mir maßgeschneiderte Themen-Targetings?</h3>
          <ul class="content-ul">
            <li>maximaler Komfort bei Themen-Targetings</li>
            <li>einfach Themen-Produkt im Tool auswählen und auf einschlägigen Branchen-Seiten ausgespielt werden</li>
            <li>Das branchenspezifische Content-Targeting über das gesamte programmatische Netzwerk rundet dieses Angebot perfekt ab</li>
          </ul>
          <div class="bild-box margin-50-0">
            <img src="@/assets/img/img-content/digital/programmatic-special-interest.jpg" alt="Special Interest" class="img-fluid shadow" />
          </div>
          <p>Auswählen können Sie die Themen-Targetings direkt im Programmatic Tool unter den Punkten <strong>Premium-Portfolio</strong> und <strong>Special Interest</strong>.</p>
        </div>
      </div>
    </Collapsible>
    <div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/digital/Programmatic-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Programmatic Advertising
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>396 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'ProgrammaticAdvertising',
  components: {
    CollapsibleButton,
    Collapsible,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
    };
  },
});
</script>
