
import { defineComponent, ref } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'ProgrammaticAdvertising',
  components: {
    CollapsibleButton,
    Collapsible,
  },
  data() {
    return {
      toggle: false,
      toggle2: false,
    };
  },
});
